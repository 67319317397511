const Repair_AIDA_Data = [
    {
        id: 0,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/1.jpg'
    },
    {
        id: 2,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/2.jpg'
    },
    {
        id: 3,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/3.jpg'
    },
    {
        id: 4,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/4.jpg'
    },
    {
        id: 5,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/5.jpg'
    },
    {
        id: 6,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/6.jpg'
    },
    {
        id: 7,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/7.jpg'
    },
    {
        id: 8,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/8.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/9.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/9.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/9.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/9.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/9.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/10.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/11.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/12.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/13.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/14.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/15.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/16.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/17.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/18.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/19.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/20.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/21.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/24.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/22.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/AIDA/23.jpg'
    },
]
export default Repair_AIDA_Data;
const Installation_HF_at_SAM_Data = [
    {
        id: 0,
        date: '',
        desc: '',
        img:'/images/vte/installation/H&F_at_SAM/01-01.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/H&F_at_SAM/02-02.jpg'
    },
    {
        id: 2,
        date: '',
        desc: '',
        img:'/images/vte/installation/H&F_at_SAM/03-03.jpg'
    },
    {
        id: 3,
        date: '',
        desc: '',
        img:'/images/vte/installation/H&F_at_SAM/04-04.jpg'
    },
    {
        id: 4,
        date: '',
        desc: '',
        img:'/images/vte/installation/H&F_at_SAM/05-05.jpg'
    },
    {
        id: 5,
        date: '',
        desc: '',
        img:'/images/vte/installation/H&F_at_SAM/06-06.jpg'
    },
    {
        id: 6,
        date: '',
        desc: '',
        img:'/images/vte/installation/H&F_at_SAM/07-07.jpg'
    },
    {
        id: 7,
        date: '',
        desc: '',
        img:'/images/vte/installation/H&F_at_SAM/08-08.jpg'
    },
    {
        id: 8,
        date: '',
        desc: '',
        img:'/images/vte/installation/H&F_at_SAM/09-09.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/installation/H&F_at_SAM/10-10.jpg'
    },
    {
        id: 10,
        date: '',
        desc: '',
        img:'/images/vte/installation/H&F_at_SAM/11-11.jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/H&F_at_SAM/12-12.jpg'
    }
]
export default Installation_HF_at_SAM_Data;
const Fagor_SLAB_Data = [
    {
        id: 0,
        date: '',
        desc: '',
        img:'/images/vte/installation/Fagor_SLAB/01-01.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/Fagor_SLAB/02-02.jpg'
    },
    {
        id: 2,
        date: '',
        desc: '',
        img:'/images/vte/installation/Fagor_SLAB/03-03.jpg'
    },
    {
        id: 3,
        date: '',
        desc: '',
        img:'/images/vte/installation/Fagor_SLAB/04-04.jpg'
    },
    {
        id: 4,
        date: '',
        desc: '',
        img:'/images/vte/installation/Fagor_SLAB/05-05.jpg'
    },
    {
        id: 5,
        date: '',
        desc: '',
        img:'/images/vte/installation/Fagor_SLAB/06-06.jpg'
    },
    {
        id: 6,
        date: '',
        desc: '',
        img:'/images/vte/installation/Fagor_SLAB/07-07.jpg'
    },
    {
        id: 7,
        date: '',
        desc: '',
        img:'/images/vte/installation/Fagor_SLAB/08-08.jpg'
    },
    {
        id: 8,
        date: '',
        desc: '',
        img:'/images/vte/installation/Fagor_SLAB/09-09.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/installation/Fagor_SLAB/10-10.jpg'
    },
    {
        id: 10,
        date: '',
        desc: '',
        img:'/images/vte/installation/Fagor_SLAB/11-11.jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/Fagor_SLAB/12-12.jpg'
    }
]
export default Fagor_SLAB_Data;
const Repair_SLAB2 = [
    {
        id: 0,
        date: '',
        desc: '',
        img:'/images/vte/repair/SLAB2/1.jpg'
    },
    {
        id: 2,
        date: '',
        desc: '',
        img:'/images/vte/repair/SLAB2/2.jpg'
    },
    {
        id: 3,
        date: '',
        desc: '',
        img:'/images/vte/repair/SLAB2/3.jpg'
    },
    {
        id: 4,
        date: '',
        desc: '',
        img:'/images/vte/repair/SLAB2/4.jpg'
    },
    {
        id: 5,
        date: '',
        desc: '',
        img:'/images/vte/repair/SLAB2/5.jpg'
    },
    {
        id: 6,
        date: '',
        desc: '',
        img:'/images/vte/repair/SLAB2/6.jpg'
    },
    {
        id: 7,
        date: '',
        desc: '',
        img:'/images/vte/repair/SLAB2/7.jpg'
    },
    {
        id: 8,
        date: '',
        desc: '',
        img:'/images/vte/repair/SLAB2/8.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/SLAB2/9.jpg'
    }
]
export default Repair_SLAB2;
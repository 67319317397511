const Maintainance_BP5_1_Data = [
    {
        id: 0,
        date: '',
        desc: '',
        img:'/images/vte/preventing_mantenance/SAB_BP5-1/1.jpg'
    },
    {
        id: 2,
        date: '',
        desc: '',
        img:'/images/vte/preventing_mantenance/SAB_BP5-1/2.jpg'
    },
    {
        id: 3,
        date: '',
        desc: '',
        img:'/images/vte/preventing_mantenance/SAB_BP5-1/3.jpg'
    },
    {
        id: 4,
        date: '',
        desc: '',
        img:'/images/vte/preventing_mantenance/SAB_BP5-1/4.jpg'
    },
    {
        id: 5,
        date: '',
        desc: '',
        img:'/images/vte/preventing_mantenance/SAB_BP5-1/5.jpg'
    },
    {
        id: 6,
        date: '',
        desc: '',
        img:'/images/vte/preventing_mantenance/SAB_BP5-1/6.jpg'
    }
]
export default Maintainance_BP5_1_Data;
const RepairChinFong = [
    {
        id: 0,
        date: '',
        desc: '',
        img:'/images/vte/repair/chin_fong/1.jpg'
    },
    {
        id: 2,
        date: '',
        desc: '',
        img:'/images/vte/repair/chin_fong/2.jpg'
    },
    {
        id: 3,
        date: '',
        desc: '',
        img:'/images/vte/repair/chin_fong/3.jpg'
    },
    {
        id: 4,
        date: '',
        desc: '',
        img:'/images/vte/repair/chin_fong/4.jpg'
    },
    {
        id: 5,
        date: '',
        desc: '',
        img:'/images/vte/repair/chin_fong/5.jpg'
    },
    {
        id: 6,
        date: '',
        desc: '',
        img:'/images/vte/repair/chin_fong/6.jpg'
    },
    {
        id: 7,
        date: '',
        desc: '',
        img:'/images/vte/repair/chin_fong/7.jpg'
    },
    {
        id: 8,
        date: '',
        desc: '',
        img:'/images/vte/repair/chin_fong/8.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/chin_fong/9.jpg'
    }
]
export default RepairChinFong;
const Repair_SchulerData = [
    {
        id: 0,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/1.jpg'
    },
    {
        id: 2,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/2.jpg'
    },
    {
        id: 3,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/3.jpg'
    },
    {
        id: 4,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/4.jpg'
    },
    {
        id: 5,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/5.jpg'
    },
    {
        id: 6,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/6.jpg'
    },
    {
        id: 7,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/7.jpg'
    },
    {
        id: 8,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/8.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/9.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/9.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/9.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/9.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/9.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/10.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/11.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/12.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/13.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/14.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/15.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/repair/Schuler/16.jpg'
    }
]
export default Repair_SchulerData;
const HydraulicLCMData = [
    {
        id: 0,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/1.jpg'
    },
    {
        id: 2,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/2.jpg'
    },
    {
        id: 3,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/3.jpg'
    },
    {
        id: 4,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/4.jpg'
    },
    {
        id: 5,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/5.jpg'
    },
    {
        id: 6,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/6.jpg'
    },
    {
        id: 7,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/7.jpg'
    },
    {
        id: 8,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/8.jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/9.jpg'
    },
    {
        id: 10,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/10.jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/11.jpg'
    },
    {
        id: 12,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/12.jpg'
    },
    {
        id: 13,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/13.jpg'
    },
    {
        id: 14,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/14.jpg'
    },
    {
        id: 15,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/15.jpg'
    },
    {
        id: 16,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/20.jpg'
    },
    {
        id: 17,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/17.jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/18.jpg'
    },
  
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/19.jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/16.jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/21.jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/22.jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/43.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/44.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/25.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/26.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/27.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/28.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/29.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/30.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/31.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/32.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/33.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/34.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/35.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/36.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/37.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/38.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/39.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/40.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/24.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/42.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/23.jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/HydraulicLCM_2500/41.jpg'
    }
]
export default HydraulicLCMData;
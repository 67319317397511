const VersionData = [
    {
        id: 0,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/2-2 (Medium).jpg'
    },
    {
        id: 2,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/4-4 (Medium).jpg'
    },
    {
        id: 3,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/5-5 (Medium).jpg'
    },
    {
        id: 4,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/6-6 (Medium).jpg'
    },
    {
        id: 5,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/7-7 (Medium).jpg'
    },
    {
        id: 6,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/8-8 (Medium).jpg'
    },
    {
        id: 7,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/9-9 (Medium).jpg'
    },
    {
        id: 8,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/10-10 (Medium).jpg'
    },
    {
        id: 9,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/11-11 (Medium).jpg'
    },
    {
        id: 10,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/12-12 (Medium).jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/13-13 (Medium).jpg'
    },
    {
        id: 12,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/14-14 (Medium).jpg'
    },
    {
        id: 13,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/15-15 (Medium).jpg'
    },
    {
        id: 14,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/16-16 (Medium).jpg'
    },
    {
        id: 15,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/17-17 (Medium).jpg'
    },
    {
        id: 16,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/18-18 (Medium).jpg'
    },
    {
        id: 17,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/19-19 (Medium).jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/20-20 (Medium).jpg'
    },
  
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/27-27 (Medium).jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/29-29 (Medium).jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/30-30 (Medium).jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/31-31 (Medium).jpg'
    },
    {
        id: 11,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/26-26 (Medium).jpg'
    },
    {
        id: 1,
        date: '',
        desc: '',
        img:'/images/vte/installation/VERSION/3-3 (Medium).jpg'
    }
]
export default VersionData;